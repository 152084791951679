(function() {
  var module = "contact";

  var $window = $(document);
  var $body = $("html, body");
  var $form = $("#contactForm");
  var $cvContainer = $("#cv-container");
  var $contactReason = $("#contactReason");
  var $submitLoader = $("#submit-loader");
  var $spiner = $("#submit-loader .s-loader");
  var $confirmMsg = $("#submit-loader .text-loader");

  $contactReason.on("change", function() {
    if ($contactReason.val() == 6) {
      $cvContainer.removeClass("hide");
      $cvContainer.addClass("show");
    } else {
      $cvContainer.removeClass("show");
      $cvContainer.addClass("hide");
    }
  });

  App.events.suscribe(module + "Send", _render);

  sendForm();

  function sendForm() {
    $form.on("submit", sendData);
  }

  function sendData(event) {
    event.preventDefault();

    $form.validate({
      rules: {
        CV: {
          maxsize: 5 * 1048576 // Convert to MB
        }
      }
    });

    if ($form.valid()) {
      var data = setData();
      $spiner.show();
      $submitLoader.show();

      App.makeDataAjaxCall(
        APP_CONFIG.BACK_URL + "api/public/contact",
        "POST",
        data,
        function(response) {
          App.events.fire(module + "Send", response);
          $form.trigger("reset");
        }
      );
    }
  }

  function setData() {
    var name = $("#contactName").val(),
      email = $("#contactEmail").val(),
      message = $("#contactMessage").val(),
      address = $("#contactAddress").val(),
      local_phone = $("#contactPhone").val(),
      user_city = $("#contactCity").val(),
      mobile_phone = $("#contactMobile").val(),
      reason = $("#contactReason").val();
    cv_file = $("#CV").prop("files")[0];

    var data = new FormData();

    data.append("fullname", name);
    data.append("email", email);
    data.append("msg", message);
    data.append("address", address);
    data.append("reason", reason);
    data.append("local_phone", local_phone);
    data.append("cv_file", cv_file);

    if (user_city != "") data.append("city", user_city);
    if (mobile_phone != "") data.append("mobile_phone", mobile_phone);

    return data;
  }

  function _render(data) {
    $confirmMsg.show();
    $spiner.hide();
    setTimeout(function() {
      $confirmMsg.hide();
      $submitLoader.hide();
    }, 3000);
  }

  // Limit the size of each individual file in a FileList.
  $.validator.addMethod(
    "maxsize",
    function(value, element, param) {
      if (this.optional(element)) {
        return true;
      }

      if ($(element).attr("type") === "file") {
        if (element.files && element.files.length) {
          for (var i = 0; i < element.files.length; i++) {
            if (element.files[i].size > param) {
              return false;
            }
          }
        }
      }

      return true;
    },
    $.validator.format("El archivo no debe ser mayor a {0} bytes.")
  );
})();
